import styled from "styled-components";
import { ListGroup } from 'react-bootstrap';

// export const StyledLink = styled(Link).attrs((props) => ({ className: isActive(props) }))`
export const StyledListNoBorder = styled(ListGroup)`
  & > .list-group-item {
    border: none;
    padding: 0.25rem 0;
    background-color: transparent;
  } 
`;

export const StyledListNoPadding = styled(ListGroup)`
  margin: 0 auto;
  padding: 0; 
  
  & > .list-group-item {
    border: none;
    padding: 0.25rem 0;
    background-color: transparent;
  }
`;

export const StyledHorizontalList = styled(ListGroup)`
  margin: 0 auto;
  padding: 0; 

  & > .list-group-item:first-child {
    padding-left: 0px; 
    border-right: solid 1px var(--gray-line);
  }

  & > .list-group-item {
    border: none;
    padding: 0 0.5rem;
    background-color: transparent;
  }

  & > .list-group-item .active {
    font-weight: bold; 
  }
`;